import { createRouter, createWebHistory } from 'vue-router'

import EventList from '@/components/pages/EventList'
import AuthUser from '@/components/pages/AuthUser'

const routes = [
    {
        path: '/',
        name: 'AuthUser',
        component: AuthUser
    },
    {
        path: '/events',
        name: 'EventList',
        component: EventList
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router