import { createApp } from 'vue'
import App from './App.vue'
import { loadFonts } from './plugins/webfontloader'
import router from './router'

import vuetify from "./plugins/vuetify";
/*
import { createVuetify } from 'vuetify/lib/framework.mjs'
import * as components from 'vuetify/lib/components/index.mjs'
const vuetify = createVuetify({
  components,
})
*/

loadFonts()

createApp(App)
  .use(vuetify)
  .use(router)
  .mount('#app')
